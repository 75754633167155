import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";


function Nav() {


  const [darw, setDarw] = useState(true);
  const [userLogin, setUser] = useState([]);

  const [nav, setNav] = useState([{
    userlist:false ,
    binventory:false ,
    subscription:true ,
    product:false ,
    catalog:false ,
    warehouse:false ,
    khata:false ,
    manageleads:false ,
    mattersheet:false ,
    logistics:false ,
    logistics_driver:false ,
    newbusiness:false ,
    ad:false ,
    office:false ,
    darw:false
  }]);

  const navigate = useNavigate();


  const changeNavList = (i, changes) => {
    const a=0;
    const oldArr = [...nav];
    const oldOb = oldArr[a];
    oldArr[a] = { ...oldOb, ...changes };
    localStorage.setItem("nav",JSON.stringify(oldArr))
    setNav(oldArr);
    
  }

  useEffect(() => {
const as=JSON.parse(localStorage.getItem("nav"))
if(as==null)
{
localStorage.setItem("nav",JSON.stringify(nav))
}else{
  setNav(as)
}




   
  }, []);

  useEffect(() => {
  
  }, []);

  return (
    <>
      

      {darw ? (
        <>
          {" "}
          <div className="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
            <div className="nano">
              <div className="nano-content">
                <div className="logo">
                  <span>
                    <img
                      onClick={() => {
                        navigate("/");
                      }}
                      style={{ marginTop: "20px", cursor: "pointer" }}
                      height={100}
                      src="assets/images/logo.png"
                      alt=""
                    />
                  </span>
                </div>
                <ul>
 
                    <li
                      style={{ color: "white", cursor: "pointer" }}
                      className=" my-3 mx-3">

                        <a href="/">
                      <span className="sidebar-sub-toggle">
                        <i className="ti-home"></i> Dashboard
                      </span>
                      </a>
                    </li>
                    <li
                      style={{ color: "white", cursor: "pointer" }}
                      className=" my-3 mx-3">

                        <a href="/seo">
                      <span className="sidebar-sub-toggle">
                        <i className="ti-home"></i> All URL List
                      </span>
                      </a>
                    </li>

                    {/* <li
                      style={{ color: "white", cursor: "pointer" }}
                      className=" my-3 mx-3">

                        <a href="/userlist">
                      <span className="sidebar-sub-toggle">
                        <i className="ti-home"></i> User list
                      </span>
                      </a>
                    </li>
                    <li
                      style={{ color: "white", cursor: "pointer" }}
                      className=" my-3 mx-3">

                        <a href="/balance">
                      <span className="sidebar-sub-toggle">
                        <i className="ti-home"></i> Balance
                      </span>
                      </a>
                    </li>
                    <li
                      style={{ color: "white", cursor: "pointer" }}
                      className=" my-3 mx-3">

                        <a href="/subscription">
                      <span className="sidebar-sub-toggle">
                        <i className="ti-home"></i> Packages
                      </span>
                      </a>
                    </li> */}
                

            

                

                  
                
                

           
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <!-- /# sidebar --> */}

      <div className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 ">
              <div
                onClick={() => {
                  setDarw(!darw);
                }}
                className=" float-left"
              >
                <div className="hamburger sidebar-toggle">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>
            

              <div className="float-right">

             

              
                <div className="dropdown dib"></div>
                <div className="header-icon" data-toggle="dropdown">
             
                </div>

                <span
                  className="user-avatar text-uppercase"
                  style={{ marginRight: "25px" }}
                >
                  {userLogin.length > 0 ? <>{userLogin[0].name}</> : <></>}
                </span>
                <div
                  style={{ marginTop: "8px" }}
                  className=" dropdown dib dropdown show "
                >
                  <a
                    className="btn btn-dark dropdown-toggle "
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i style={{ size: "100px" }} className="ti-user "></i>
                  </a>

                  <div
                    className="dropdown-menu drop-down dropdown-profile  dropdown-menu-right user-avatar"
                    aria-labelledby="dropdownMenuLink"
                  >
                    
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.removeItem("master");
                        navigate("/login");
                      }}
                      className="dropdown-item"
                    >
                      <i className="mx-2 ti-power-off"></i> Log Out
                    </a>
                  </div>
                </div>

                {/* <div className="dropdown dib">
                  <div className="header-icon" data-toggle="dropdown">
                    <span className="user-avatar text-uppercase"></span>
                    <div className="drop-down dropdown-profile dropdown-menu dropdown-menu-right">
                      <div>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="ti-user"></i>
                              <span>Personal Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="#">
                              <i className="ti-email"></i>
                              <span>Business Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="#">
                              <i className="ti-settings"></i>
                              <span>Director Profile</span>
                            </a>
                          </li>

                          <li>
                            <a href="/login">
                              <i className="ti-lock"></i>
                              <span>Certificate & Awards</span>
                            </a>
                          </li>
                          <li
                            onClick={() => {
                              localStorage.removeItem("USER");
                              navigate("/login");
                            }}
                          >
                            <a href="#">
                              <i className="ti-power-off"></i>
                              <span>Logout</span>
                            </a>
                          </li>

                          <li
                            onClick={() => {
                              alert("hi");
                              localStorage.removeItem("USER");
                              navigate("/login");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <a href="/login">
                              <i className="ti-close"></i> Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;