import Nav from "./navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

function Mainpage() {
  const navigate = useNavigate();

  const [uid, setUid] = useState("");
  const [lead, setLead] = useState([]);
  const [uiduser, setuiduser] = useState("");

  const [url, setURL] = useState('');
  const [h1, setH1] = useState('');
  const [title, setTitle] = useState('');
  const [des, setDes] = useState('');
  const [keyword, setKeyword] = useState('');
  const [allSeo, setAllSeo] = useState([]);

  useEffect(() => {
   

    const v = JSON.parse(localStorage.getItem("master"));
   
    
    if (v === null) {
      navigate("/login");
      
    } 
    ViewSeo();
    }, []);

  const ViewSeo = async () => {
    const data = new FormData();
    data.append("url","");
    
   
    axios({
      method: "post",
      url: "/api/view.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        
    
        setAllSeo(s.data)
        console.log("804",s.data)
       
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };


  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                   
                   
               <h1>Seo Portal</h1>
               <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">URL</th>
      <th scope="col">H1</th>
      <th scope="col">Title</th>
      <th scope="col">Description</th>
      <th scope="col">Keyword</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {
        allSeo.map((v,i)=>(
            <tr>
            <th scope="row">{v.id}</th>
            <td>{v.url}</td>
            <td>{v.h1}</td>
            <td>{v.t}</td>
            <td>{v.d}</td>
            <td>{v.k}</td>
            <td><a href={"/editseo?id="+v.id}><button type="button" className="btn btn-success">Edit</button></a> </td>
            
          </tr>
        ))
    }

    
  </tbody>
</table>
               </div>
                

                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
