import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Login() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const loginNow = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("email", email);
    data.append("pass", pass);
    data.append("status", "login");

    axios({
      method: "post",
      url: "/api/login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        // console.log(s.data);
        

        if (s.data[0].msg === "pass") {
          localStorage.setItem("master", JSON.stringify(s.data));
          navigate("/");

        }  else {

          if(s.data[0].msg === "block")
          {
            alert("Account Block! contact Admin");
          }else{
            alert("Invalid Email or Password! Please try again.");
          }
          
        }
      })
      .catch((s) => {
        // console.log("here catch ", s);
      });
  };

  return (
    <body
      style={{
        backgroundImage: `url("https://alahdeen.com/pro/image/test.jpg")`,
        // backgroundColor: "dodgerblue",
        height: "100vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="unix-login">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="login-content">
                <div className="login-logo">
               
                  
                </div>
                <div style={{ opacity:0.9,}} className="login-form">
                  <center>
                    <img
                    
                      onClick={() => {
                        navigate("/");
                        // window.open("https://alahdeen.com/", "_blank");
                      }}
                      style={{
                        marginTop: "20px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      src="assets/images/logob.png"
                      height={100}
                    />
                  </center>
                

                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="username"
                      onChange={(v) => {
                        setEmail(v.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type={show ? "text" : "password"}
                      className="form-control"
                      placeholder="Password"
                      onChange={(v) => {
                        setPass(v.target.value);
                      }}
                    />
                  </div>
                  <div className="checkbox">
                    <label
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      {show ? (
                        <>
                          {" "}
                          <input type="checkbox" checked /> Show Password
                        </>
                      ) : (
                        <>
                          {" "}
                          <input type="checkbox" unchecked /> Show Password
                        </>
                      )}
                    </label>
                   
                  </div>
                  <button
                    onClick={(e) => {
                      loginNow(e);
                    }}
                    type="submit"
                    className="btn btn-primary btn-flat m-b-30 m-t-30"
                  >
                    Sign in
                  </button>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Login;
