import Nav from "./navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

function Mainpage() {
  const navigate = useNavigate();

  const [uid, setUid] = useState("");
  const [lead, setLead] = useState([]);

  const [id, setId] = useState("");

  const [url, setURL] = useState('');
  const [h1, setH1] = useState('');
  const [title, setTitle] = useState('');
  const [des, setDes] = useState('');
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
     const v = JSON.parse(localStorage.getItem("master"));
    if (v === null) {
      navigate("/login");
    } 
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    getID(id)
    setId(id)

    }, []);

  const getID = async (id) => {
    const data = new FormData();
    data.append("id",id);

    axios({
      method: "post",
      url: "/api/viewbyid.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        
        setURL(s.data[0].url)
        setH1(s.data[0].h1)
        setTitle(s.data[0].t)
        setDes(s.data[0].d)
        setKeyword(s.data[0].k)

        console.log("804:",s.data)
       
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };

  const addNow = async () => {
    const data = new FormData();
    data.append("id",id);
    data.append("url",url);
    data.append("h1",h1);
    data.append("title",title);
    data.append("keyword",keyword);
    data.append("des",des);

   
    axios({
      method: "post",
      url: "/api/edit.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((s) => {
        
       
        console.log("804:",s.data)
        alert("Updated")
       
      })
      .catch((s) => {
        console.log("here catch =>>", s);
      });
  };


  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                   
                   
               <h1>Seo Portal</h1>   
               
    <div class="form-group">
      <label for="usr">URL</label>
      <input type="text" class="form-control" id="usr" name="url"
      value={url}
      onChange={(v) => {
        setURL(v.target.value);
      }}  />
    </div>

    <div class="form-group">
      <label for="pwd">H1 (Characters :{h1.length}) Stick to 60 characters for the H1 heading length</label>
      <input type="text" class="form-control" id="pwd" name="h1"
      value={h1}
      onChange={(v) => {
        setH1(v.target.value);
      }}/>
    </div>

    <div class="form-group">
      <label for="pwd">Meta Title (Characters :{title.length}) The ideal length for the meta title is 50–60 characters.</label>
      <input type="text" class="form-control" id="pwd" name="title"
      value={title}
      onChange={(v) => {
        setTitle(v.target.value);
      }}/>
    </div>

    <div class="form-group">
      <label for="pwd">Meta Description ( 150-160 characters {des.length} )</label>
      <input type="text" class="form-control" id="pwd" name="des"
     value={des}
     onChange={(v) => {
        setDes(v.target.value);
      }}/>
    </div>

    <div class="form-group">
      <label for="pwd">Meta Keyword</label>
      <input type="text" class="form-control" id="pwd" name="keyword"
      value={keyword}
      onChange={(v) => {
        setKeyword(v.target.value);
      }}
      />
    </div>



    <button onClick={()=>{
      if(url.length<9)
      {
        alert("URL Worng")
      }else{
    addNow()        
      }
    }}  class="btn btn-primary">Submit</button>
  
                  
          </div>
                

                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
