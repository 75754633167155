import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Master/Dashboard";
import Login from "./Master/Login";
import SEOList from "./Master/Seo_list";
import Edit_seo from "./Master/Editseo";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
         

          <Route path="/" element={<Dashboard />} />
          <Route path="/seo" element={<SEOList />} />
          <Route path="/editseo" element={<Edit_seo />} />
          <Route path="/login" element={<Login />} />
          

          



          

        
          

         


        </Routes>

       
      </BrowserRouter>
    </>
  );
}

export default App;
